.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.page .outlet {
  padding: var(--scale-4) var(--scale-5);
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

.page .content-container {
  height: 100vh;
  overflow: hidden;
}

.page.homepage-container .outlet {
  height: 100%;
  padding: unset;
}

.header {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(67, 117, 255, 1) 100%
  );
  color: white;
}

.header .inner {
  margin: var(--scale-1);
  display: flex;
}

.header .inner img.logo {
  height: 70px;
  border-radius: 100%;
  margin: 0 var(--scale-2);
  margin-right: var(--scale-1);
}

.header .inner .title {
  color: white;
  font-size: 26px;
  line-height: 70px;
  margin: 0 var(--scale-2);
}

.header .inner .title .sub-title {
  display: inline;
}

.header .inner .title .break {
  margin: 0 var(--scale-2);
}

.sidebar {
  height: 100%;
  max-width: 350px;
  min-width: 200px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  z-index: 1;
  padding: var(--scale-3);
  background: white;
  position: relative;
  right: -100%;
  transition: right 0.3s;
}

.sidebar-container.show .sidebar {
  right: 0;
}

.sidebar a.menu-item {
  outline: unset;
  padding: var(--scale-2) 0 var(--scale-2) var(--scale-4);
  border-radius: var(--border-radius);
  cursor: pointer;
  display: block;
  color: var(--main-color);
  text-decoration: unset;
  border-left: 6px solid transparent;
}

.sidebar a.menu-item.selected {
  background: #f8f8f8;
  border-color: var(--header-color);
}

.mobile-menu {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 9;
}

.mobile-menu .sidebar-container {
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  background: #918f8f91;
  z-index: 1;
  visibility: hidden;
}

.mobile-menu .sidebar-container.show {
  visibility: visible;
}

.mobile-menu .icon {
  width: 24px;
  margin: var(--scale-2);
  cursor: pointer;
}

.mobile-menu .sidebar-container .sidebar {
  width: 60%;
  max-width: 250px;
  margin-left: auto;
}

.mobile-menu .sidebar-container .sidebar .menu-item {
  max-width: 200px;
  margin-left: auto;
}

@media only screen and (max-width: 800px) {
  .page .outlet {
    padding: var(--scale-1);
  }

  .header .inner .title {
    font-size: 14px;
    line-height: unset;
    margin: var(--scale-1);
  }

  .header .inner .title .sub-title {
    display: block;
    font-size: 14px;
    line-height: unset;
  }

  .header .inner img.logo {
    margin: 4px;
    height: 38px;
  }
}
