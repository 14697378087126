body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.half {
  width: 50%;
}
