@media only screen and (max-width: 850px) {
  .hide-mobile {
    display: none;
  }

  .show-mobile {
    display: flex;
  }

  .no-mobile-flex {
    display: block;
  }

  .mobile-scroll {
    overflow: scroll !important;
  }
}

@media only screen and (min-width: 850px) and (max-width: 1100px) {
  .hide-small-size {
    display: none;
  }
}

@media only screen and (min-width: 1100px) {
  .hide-large-size {
    display: none;
  }
}
