.contacts {
  max-width: 1024px;
  margin: auto;
}

.contacts .info {
  max-width: 800px;
  font-size: 18px;
  padding: 30px 0;
}

.contact .mfa-approved {
  display: flex;
  float: right;
}

.contact .mfa-approved img {
  display: block;
}

.contacts-container {
  margin-top: var(--scale-4);
}

.contacts .contact {
  background: white;
  padding: var(--scale-4);
  margin: var(--scale-3);
  box-shadow: 0 0 15px 0 rgba(45, 46, 116, 0.07);
  transition: box-shadow 0.3s;
  border: 1px solid transparent;
}

.contacts .contact h3 {
  width: 100%;
}

.contacts .contact h3:before {
  border: unset;
}

.contacts .contact:hover {
  box-shadow: 0 0 15px 0 rgba(45, 46, 116, 0.25);
}

.contacts .contact.active {
  border-color: #cedaff;
}

.contacts .contact h3 {
  margin-bottom: var(--scale-2);
}

.contacts .contact .org-name {
  margin-bottom: var(--scale-2);
}

.contacts .contact .mfa-approved .label {
  margin: 0 0 0 var(--scale-1);
}

.contacts .contact .label {
  font-weight: var(--semi-bold);
  margin: var(--scale-1) 0;
  font-size: 13px;
}

.contacts .contact .content {
  font-size: 11px;
}

.contacts .contact .title .divider {
  font-size: 110%;
}

.contacts .contact .title .type {
  background: #e4e4e4;
  border-radius: 12px;
  padding: 3px 6px;
  font-size: 11px;
  font-weight: normal;
  color: black;
  text-transform: capitalize;
  font-style: italic;
  vertical-align: middle;
}

.contacts .contact .message {
  font-size: 90%;
  margin-top: var(--scale-2);
  background: #faf7f7;
  padding: var(--scale-3);
}

.contacts .contact .message p {
  margin: unset;
}

.contacts .contact .column {
  min-width: 150px;
  overflow: hidden;
  padding: var(--scale-2);
}

.contacts .contact .no-left-padding {
  padding-left: 0;
}

.contacts .contact .details {
  justify-content: left;
}

@media only screen and (max-width: 800px) {
  .contacts .contact {
    border: unset;
    border-bottom: 4px solid var(--header-color);
  }
}
