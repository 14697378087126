.homepage {
  justify-content: center;
}

.homepage .inner-content {
  padding: var(--scale-4) var(--scale-5);
  justify-content: center;
  gap: 60px;
}

.homepage .left-side {
  width: 750px;
}

.homepage .right-side {
  width: 400px;
  margin: 0 var(--scale-4);
}

.divider {
  border-bottom: 1px solid #e7e7e7;
  margin: var(--scale-4) 0;
}

.daily-message {
  font-family: "Montserrat", sans-serif;
  margin-bottom: var(--scale-4);
}

.daily-message .title {
  justify-content: start;
}

.daily-message .text {
  overflow: hidden;
}

.daily-message .text.show {
  opacity: 1;
}

.link-to-info {
  padding: 20px;
  border: 1px solid;
  border-radius: 8px;
  transition: background-color 0.2s;
  background-color: transparent;
  font-size: 14px;
  width: 300px;
  margin-bottom: var(--scale-2);
}

.link-to-info .label {
  line-height: 30px;
  color: var(--main-color);
  margin-left: var(--scale-1);
  font-weight: var(--semi-bold);
}

.link-to-info:hover {
  background-color: var(--third-color);
  color: white;
}

.link-to-info.kidnapped {
  background: rgb(200, 75, 52);
  border-color: rgb(200, 75, 52);
  justify-content: center;
}

.link-to-info.kidnapped .label {
  color: white;
  font-size: 22px;
}

.link-to-info .icon {
  height: 30px;
}
.homepage .mfa-link {
  line-height: 50px;
  font-size: 20px;
  font-weight: var(--semi-bold);
  justify-content: left;
  gap: 8px;
}

.social-media-icons {
  margin-bottom: var(--scale-2);
}

.social-media-icons .container {
  justify-content: left;
  color: var(--header-color);
}

.social-media-icons img {
  width: 20px;
}

.social-media-icons .label {
  margin-left: var(--scale-1);
}

.social-media-icons .item {
  margin-bottom: var(--scale-1);
}

.homepage .video-carousel {
  height: 400px;
  overflow: hidden;
  position: relative;
}

.homepage .video-carousel video {
  width: 100%;
  margin-top: -100px;
}

.homepage .video-carousel video {
  width: 100%;
}

.homepage .video-carousel .circles {
  margin: 100px auto;
  justify-content: center;
  gap: 20px;
}

.homepage .video-carousel .circle {
  background: var(--background-color);
  height: 8px;
  width: 40px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
}

.homepage .video-carousel .circle .progress {
  height: 100%;
  background: #007bff;
}

.homepage .video-carousel .circle.selected {
}

.homepage .video-carousel .cover .text {
  margin: auto;
  padding: 60px 0;
  width: 800px;
  flex-direction: column;
  justify-content: space-between;
}

.homepage .video-carousel .cover .text .title {
  font-size: 34px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.homepage .video-carousel .cover .text .credit {
  font-size: 16px;
  color: white;
  margin-top: var(--scale-4);
}

.homepage .video-carousel .cover .text .credit a {
  color: white !important;
  text-decoration: underline;
}

.homepage .video-carousel .cover {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(93 93 93 / 55%);
  z-index: 2;
}

@media only screen and (max-width: 800px) {
  .homepage {
    display: flex;
    flex-direction: column-reverse;
  }

  .homepage .right-side {
    max-width: unset;
    margin: unset;
    width: unset;
  }

  .homepage .left-side {
    max-width: unset;
    width: unset;
  }

  .daily-message {
    font-size: 100%;
  }

  .homepage .link-to-info {
    width: fit-content;
  }
}
