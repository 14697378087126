.daily-calendar-icon {
  margin-left: var(--scale-3);
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
}

.daily-calendar-icon .day {
  width: 100%;
  height: 22px;
  position: absolute;
  background-color: #4973ee;
  border-radius: 10px 10px 0 0;
  border-bottom: 3px solid #e5e5e5;
  font:
    400 15px/22px Arial,
    Helvetica,
    Geneva,
    sans-serif;
  color: #fff;
  text-align: center;
}

.daily-calendar-icon .day-of-month {
  width: 100%;
  position: absolute;
  top: 28px;
  font:
    400 22px "Helvetica Neue",
    Arial,
    Helvetica,
    Geneva,
    sans-serif;
  color: #4c566b;
  text-align: center;
}
