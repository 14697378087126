.preview-modal {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.preview-modal .file-action-buttons {
  width: unset;
}

.preview-modal .close-icon {
  width: 20px;
  cursor: pointer;
}

.preview-modal .overlay {
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background: black;
  position: fixed;
}

.preview-modal .content {
  margin: 40px auto;
  background: white;
  z-index: 11;
  position: relative;
  width: 550px;
  padding: var(--scale-4);
}

.preview-modal .content .description {
  margin-top: var(--scale-1);
}

.preview-modal .content .file-name {
  margin-top: var(--scale-1);
}

.preview-modal .content .buttons {
  margin-top: var(--scale-1);
  justify-content: right;
}

.preview-modal .content .line {
  height: 2px;
  margin: var(--scale-2) auto;
  background: #f5f1f1;
}

.preview-modal .content .media-iframe {
  width: 100%;
  height: 300px;
  background: #e8e8e8;
  border: unset;
  outline: unset;
}

@media only screen and (max-width: 800px) {
  .preview-modal .content {
    width: unset;
    margin: 50px auto;
  }

  .preview-modal .tags-container {
    margin: var(--scale-2) 0;
  }
}
