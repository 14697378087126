:root {
  --scale: 6px;
  --scale-1: calc(var(--scale) * 1);
  --scale-2: calc(var(--scale) * 2);
  --scale-3: calc(var(--scale) * 3);
  --scale-4: calc(var(--scale) * 4);
  --scale-5: calc(var(--scale) * 5);

  --header-color: #1f2e80;
  --main-color: #2f2f2f;
  --second-color: #4d4d4d;
  --third-color: #a79f9f;

  --background-color: #f8f8f8;

  --border-radius: 6px;

  --box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);

  --bold: 700;
  --semi-bold: 600;

  --font-size-small: 12px;
}

body {
  background: var(--background-color);
}

h1,
h2,
h3,
h4 {
  color: var(--header-color);
  font-weight: var(--semi-bold);
  margin: unset;
  position: relative;
  width: fit-content;
}

h1 {
  font-size: 32px;
  line-height: 42px;
  margin-bottom: var(--scale-4);
}

h2 {
  font-size: 24px;
  line-height: 42px;
  margin-bottom: var(--scale-4);
}

a {
  text-decoration: unset;
  cursor: pointer;
}

button {
  border: unset;
  outline: unset;
  background: var(--header-color);
  color: white;
  padding: var(--scale-1) var(--scale-2);
  line-height: 18px;
  white-space: nowrap;
  cursor: pointer;
}

h1:before,
h2:before,
h3:before {
  content: "";
  position: absolute;
  width: 50%;
  bottom: -2px;
  border-bottom: 4px solid var(--header-color);
}

h2:before,
h3:before {
  bottom: 0;
  border-bottom: 2px solid var(--header-color);
}
