.content-library {
  margin: auto;
  max-width: 775px;
}

.content-library h1 {
  justify-content: space-between;
}

.search-results {
  margin-top: var(--scale-3);
  justify-content: left;
  gap: 10px 10px;
  flex-wrap: wrap;
  height: 100%;
}

.search-results.loading {
  opacity: 0.4;
}

.search-results .no-results {
  font-size: 20px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  padding: var(--scale-3) 0;
}

.file-template {
  color: var(--main-color);
  text-decoration: unset;
  display: flex;
  padding: var(--scale-3);
  background: white;
  margin-bottom: var(--scale-3);
  box-shadow: 0 0 15px 0 rgba(45, 46, 116, 0.07);
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.file-template:hover {
  box-shadow: 0 0 15px 0 rgba(45, 46, 116, 0.25);
}

.file-template .details {
  width: 100%;
  flex-direction: column;
  pointer-events: unset;
}

.file-template .thumbnail {
  height: 144px;
  width: 144px;
  margin-right: var(--scale-3);
  position: relative;
}

.file-template.social-media .thumbnail {
  height: unset;
  width: unset;
  background: unset;
}

.file-template.social-media .actions {
  margin-top: var(--scale-4);
}

.file-template .thumbnail > .media-iframe {
  height: 144px;
  width: 144px;
  background-size: 200% 100%;
  background: #e8e8e8;
  border: unset;
  outline: unset;
  object-fit: cover;
}

.file-template .thumbnail .icon {
  width: 25px;
  margin-right: var(--scale-1);
  position: absolute;
  top: var(--scale-1);
  left: var(--scale-1);
}

.file-template .file-name {
  line-height: 25px;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: var(--scale-1);
}

.file-template .description {
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: var(--border-radius);
  font-size: 80%;
  white-space: break-spaces;
  color: var(--second-color);
  line-height: 18px;
}

.file-template .metadata {
  justify-content: left;
  gap: var(--scale-3);
  color: #1f82ff;
  pointer-events: unset;
}

.file-template.social-media .details {
  justify-content: start;
  margin-bottom: var(--scale-2);
}

.mfa-approved {
  color: #3b6d44;
  font-size: 12px;
  margin-left: auto;
}

.mfa-approved .icon {
  padding: 3px;
  background: #84e092;
  border-radius: 100%;
  height: 14px;
  width: 14px;
}

.mfa-approved .label {
  line-height: 22px;
  margin-left: var(--scale-1);
}

.file-template .metadata .date {
  font-weight: var(--bold);
  color: var(--third-color);
  font-size: var(--font-size-small);
  line-height: 18px;
}

.file-template .metadata a.source {
  font-size: var(--font-size-small);
  line-height: 18px;
  display: block;
  font-weight: var(--bold);
}

.file-template .actions {
  justify-content: space-between;
  margin-top: auto;
}

.file-action-buttons {
  gap: var(--scale-1);
  justify-content: right;
  width: 150px;
}

.file-action-buttons.media {
  width: unset;
  justify-content: start;
  margin-top: var(--scale-2);
}

.file-action-buttons button {
  padding: var(--scale-1);
  line-height: unset;
}

.file-action-buttons img {
  height: 20px;
}

.search-box {
  justify-content: space-between;
  margin: var(--scale-4) 0;
}

.search-box .search-box-container {
  width: 230px;
  padding: var(--scale-2);
  padding-left: var(--scale-4);
  border-radius: 38px;
  background: white;
  justify-content: left;
  border: 1px solid var(--header-color);
}

.search-box .search-box-container .icon {
  width: 20px;
}

.search-box input {
  border: unset;
  outline: unset;
  font-size: 18px;
  padding-left: 10px;
}

.search-box .label {
  font-size: 20px;
  line-height: 45px;
}

.search-box .loading {
  margin-left: var(--scale-2);
  line-height: 32px;
}

.show-warning {
  background: #fff2c6;
  width: fit-content;
  padding: var(--scale-1);
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  opacity: 0.4;
  transition: 0.3s opacity;
  cursor: pointer;
}

.show-warning:hover {
  opacity: 1;
}

.content-library .warning {
  background: #fff2c6;
  padding: var(--scale-3) var(--scale-4);
  border-radius: var(--border-radius);
  margin-top: var(--scale-2);
  font-size: 14px;
  line-height: 20px;
  flex-direction: column;
}

.content-library .warning .label {
  padding: 0 var(--scale-2);
  font-size: 20px;
  text-transform: uppercase;
  color: var(--header-color);
  font-weight: bold;
  line-height: 20px;
  align-self: center;
}

.content-library .warning .text {
  margin: 0 var(--scale-3);
}

.content-library .warning button {
  padding: 4px;
  font-size: 10px;
  line-height: 10px;
  margin-left: 4px;
}

.file-template.preview-open {
  cursor: unset;
}

.content-library .tags {
  justify-content: left;
  gap: 4px;
  overflow: hidden;
  width: 100%;
  margin-top: 2px;
  padding-right: var(--scale-1);
  flex-grow: 1;
}

.content-library .tags .tag {
  background: #f5f5f5;
  padding: var(--scale-1) var(--scale-2);
  border-radius: 38px;
  font-size: 11px;
  height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  line-height: 14px;
}

.categories-filter {
  width: fit-content;
  justify-content: flex-start;
  margin: auto;
  border-bottom: 3px solid #e7e7e7;
}

.categories-filter .item {
  text-transform: capitalize;
  color: var(--header-color);
  font-weight: var(--semi-bold);
  font-size: 15px;
  line-height: 15px;
  border-bottom: 3px solid transparent;
  width: fit-content;
  text-align: center;
  transition:
    visibility 0s,
    opacity 0.1s linear;
  visibility: visible;
  opacity: 1;
  padding: var(--scale-2) var(--scale-3);
  position: relative;
  top: 2px;
}

.categories-filter .item.hide-animation {
  visibility: hidden;
  opacity: 0;
}

.categories-filter .item.active {
  border-color: var(--header-color);
}

.mobile-categories-filter {
  justify-content: left;
  padding: 0 var(--scale-4);
  position: relative;
}

.mobile-categories-filter .icon {
  width: 20px;
}

.mobile-categories-filter .active-item {
  gap: 10px;
  padding: 0 var(--scale-2);
  text-align: center;
  cursor: pointer;
}

.mobile-categories-filter .menu {
  position: absolute;
  top: 20px;
  z-index: 11;
  background: var(--background-color);
  padding: var(--scale-2) 0;
  box-shadow: var(--box-shadow);
}

.mobile-categories-filter .menu > div {
  font-weight: var(--semi-bold);
  padding: var(--scale-1) var(--scale-2);
  cursor: pointer;
}

.mobile-categories-filter .menu > div:hover {
  background: white;
}

.pagination {
  justify-content: start;
  border: 1px solid var(--header-color);
  width: fit-content;
  margin: auto;
}

.pagination button {
  font-size: 16px;
}

.pagination button:hover {
  background: unset;
  color: var(--header-color);
}

.pagination button.selected {
  background: white;
  color: var(--header-color);
}

@media only screen and (max-width: 800px) {
  .content-library {
    width: unset;
    padding: unset;
  }

  .file-template.social-media {
    display: flex;
    flex-direction: column-reverse;
  }

  .content-library .warning .label {
    padding: unset;
  }

  .content-library .warning .text {
    margin: var(--scale-2) 0 0 0;
  }

  .content-library .warning .warning-container {
  }

  .content-library .search-box input {
    width: 100%;
  }

  .file-template .details {
    justify-content: start;
  }

  .search-box .search-box-container {
    width: 100%;
  }

  .categories-filter {
    gap: 6px;
  }

  .categories-filter .item {
    font-size: 12px;
    cursor: pointer;
  }

  .file-template .thumbnail {
    height: 100px;
    width: 100px;
  }

  .file-template .thumbnail > .media-iframe {
    height: 100px;
    width: 100px;
  }
}
