.announcements {
  max-width: 300px;
}

.announcements .announcement {
  margin-bottom: var(--scale-2);
  padding: var(--scale-2);
  background: white;
  font-weight: 500;
}

.announcements .announcement p {
  line-height: 16px;
  margin: unset;
}

.announcements .announcement {
  border-left: 6px solid var(--header-color);
}

.announcement .icon {
  width: 20px;
  margin-right: var(--scale-2);
}

.announcement .content {
  width: 100%;
}
.announcement .content .date {
  color: #a79f9f;
  font-size: 10px;
  margin-bottom: var(--scale-1);
}

@media only screen and (max-width: 800px) {
  .announcements {
    max-width: unset;
  }
}
