.mission {
  max-width: 650px;
  margin: auto;
  padding: var(--scale-2);
  font-size: 18px;
  letter-spacing: 0.3px;
}

.mission img {
  width: 100%;
}

.mission .text {
  font-size: 22px;
  line-height: 28px;
}
